import React, { useState, useRef } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import util from "digimaker-ui/util";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const submit = async (event) => {
    setIsloading(true);
    let forms = event.currentTarget;
    let form = new FormData(forms);

    setValidated(true);
    const dataObject: any = {};
    for (let key of Array.from(form.keys())) {
      dataObject[key] = form.get(key);
    }
    event.preventDefault();
    if (forms.checkValidity() === false) {
      event.stopPropagation();
      setIsloading(false);
      return;
    }
    setError("");

    fetch(process.env.REACT_APP_BRIDGE_API_URL + "/clubadmin/club_login", {
      method: "POST",
      body: JSON.stringify(dataObject),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success === true) {
          const refreshToken = data.refreshToken;
          const userType = data.userType;
          const clubForm = new FormData();
          clubForm.set("Login", dataObject["username"]);
          clubForm.set("Password", dataObject["password"]);
          clubForm.set("RedirectURI", "/");

          let host = "klubb.bridge.no";
          if (userType === "krets") {
            host = "krets.bridge.no";
          }
          fetch("https://" + host + "/bridgemodule/klubb_login", {
            method: "POST",
            redirect: "manual",
            body: clubForm,
            credentials: "include",
          }).then((respClub) => {
            if (respClub.type === "opaqueredirect") {
              util.setRefreshToken(refreshToken, "/");
              window.location.href = "/website";
            }
          });
        } else {
          setIsloading(false);
          setError(data.message);
        }
      });
  };

  return (
    <div id="login" style={{ margin: "auto" }}>
      <div className="login_left"></div>
      <div className="login_right">
        <div className="logo_image"></div>
        <div className="login">
          <h3
            className="text-center"
            style={{
              margin: "42px 0 30px 0",
              fontSize: "2.25rem",
            }}
          >
            Logg inn
          </h3>
          <Form onSubmit={submit} validated={validated} noValidate>
            <Form.Group className="mb-3 form-item" controlId="formUsername">
              <Form.Label
                style={{
                  fontSize: "1.13rem",
                }}
              >
                BRUKERNAVN
              </Form.Label>
              <div className="mb-3">
                <span className="icons userIcon"> </span>
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Vennligst skriv inn brukernavn"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Vennligst skriv inn brukernavn.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  form-item"
              controlId="formBasicPassword"
            >
              <Form.Label
                style={{
                  fontSize: "1.13rem",
                }}
              >
                PASSORD
              </Form.Label>
              <div className="mb-3">
                <span className="icons passwrodIcon"> </span>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Skriv inn passord"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Skriv inn passord.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Button
              variant="danger"
              type="submit"
              style={{
                width: "100%",
                borderRadius: 0,
                background: "#C33625",
                marginTop: "50px",
                fontSize: "1.5rem",
              }}
            >
              Logg Inn
            </Button>
          </Form>
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <a href="https://www.bridge.no/user/forgotpassword">
              Glemt passord?
            </a>
          </div>
          {isloading && <span className="loading"></span>}
          {error && (
            <div>
              <br />
              <Alert variant="danger">{error}</Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
