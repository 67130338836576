import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FetchWithAuth } from "digimaker-ui/util";
import { getListConfig, isBridgeAdmin } from "../../Config";
import { List } from "digimaker-ui";
import useSiteStore from "../../store/useSiteStore";
import { ImportArticles } from "../../components/content/ImportArticles";

const names = {
  event: "aktivitet",
  file: "fil",
  image: "bilder",
  article: "artikkel",
  template: "mal",
  link: "lenke",
};

export const ContentLibrary = (props: { content; categoryID?: number }) => {
  const rootID = props.content.location.id;
  const libraryType = props.content.folder_type.value;
  const [categoryID, setCategoryID] = useState(rootID);
  const [categories, setCategories] = useState([]);

  const [listKey, setListKey] = useState(1);

  useEffect(() => {
    FetchWithAuth(
      "content/list/folder?parent=" + rootID + "&sortby=c.id desc"
    ).then((resp: any) => {
      if (resp.error === false) {
        setCategories(resp.data.list);
      }
    });
    if (props.categoryID) {
      setCategoryID(props.categoryID);
    } else {
      setCategoryID(props.content.location.id);
    }
  }, [rootID]);

  const { site } = useSiteStore();

  const switchCategory = (id) => {
    setCategoryID(id);
  };

  return (
    <div>
      <h2 className="title">{props.content.metadata.name}</h2>
      <div>
        <label>Filter: </label>
        &nbsp;
        <Button
          size="sm"
          variant={categoryID === rootID ? "outline-secondary" : "link"}
          onClick={() => switchCategory(rootID)}
        >
          {site.name}
        </Button>
        &nbsp;
        {categories.map((item: any) => (
          <Button
            size="sm"
            variant={
              categoryID === item.location.id ? "outline-secondary" : "link"
            }
            onClick={() => switchCategory(item.location.id)}
          >
            {item.metadata.name}
          </Button>
        ))}
        {["file", "image", "link"].includes(libraryType) && isBridgeAdmin() && (
          <>
            &nbsp;
            <Link to={"/website/create/folder/" + rootID} title="Ny kategori">
              <Button size="sm" variant="link" onClick={() => {}}>
                <i className="bi bi-plus-lg"></i>
              </Button>
            </Link>
          </>
        )}
      </div>
      <br />
      {/* {categoryID === rootID && ( */}
      <div className="text-right">
        {["article", "template"].includes(libraryType) && (
          <Link
            to={`/fullcreate/${libraryType}/${rootID}${
              libraryType === "article" ? "?template=6" : ""
            }`}
          >
            <Button size="sm" variant="outline-link">
              <i className="bi bi-plus-lg"></i>Ny{" "}
              {names[libraryType] || libraryType}
            </Button>
          </Link>
        )}
        {libraryType === "article" && (
          <ImportArticles
            key={rootID}
            target={rootID}
            afterSuccess={() => setListKey(listKey + 1)}
          />
        )}
        {["event", "file", "image", "link", "block"].includes(libraryType) &&
          (isBridgeAdmin() || categoryID === rootID) && (
            <Link
              to={`/website/create/${libraryType}/${categoryID}?from=/main/${rootID}`}
            >
              <Button size="sm" variant="outline-link">
                <i className="bi bi-plus-lg"></i>Ny{" "}
                {names[libraryType] || libraryType}
              </Button>
            </Link>
          )}{" "}
        {libraryType === "annual_report" && (
          <Link
            to={`/website/annual_report/create/${categoryID}?from=/main/${rootID}`}
          >
            <Button size="sm" variant="outline-link">
              <i className="bi bi-plus-lg"></i>Opprette årsmøte
            </Button>
          </Link>
        )}
        {libraryType === "image" &&
          (isBridgeAdmin() || categoryID === rootID) && (
            <Link to={"/website/image/multiupload/" + categoryID}>
              <Button size="sm" variant="outline-link">
                <i className="bi bi-plus-lg"></i>Multiupload
              </Button>
            </Link>
          )}
        {libraryType === "file" &&
          (isBridgeAdmin() || categoryID === rootID) && (
            <Link to={"/website/file/multiupload/" + categoryID}>
              <Button size="sm" variant="outline-link">
                <i className="bi bi-plus-lg"></i>Multiupload
              </Button>
            </Link>
          )}
        &nbsp;
      </div>
      <List
        id={categoryID}
        key={categoryID + "" + listKey}
        contenttype={libraryType}
        {...(getListConfig(
          categories.find((item: any) => item.location.id === categoryID) ||
            props.content,
          libraryType
        ) as any)}
      />
    </div>
  );
};
