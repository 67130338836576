import { FetchWithAuth } from "digimaker-ui/util";
import { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import useSiteStore from "../../store/useSiteStore";

export const ImportArticles = (props: {
  target: number;
  afterSuccess?: () => void;
}) => {
  const [folderID, setFolderID] = useState(0);
  const [folderTree, setFolderTree] = useState<any>(null);
  const [showSelect, setShowSelect] = useState(false);
  const { site } = useSiteStore();
  const [loading, setLoading] = useState(false);

  const showSelecting = async () => {
    const data = await FetchWithAuth(
      "/api/clubadmin/content/get_ez_folders?type=" +
        (site.clubID ? "klubb" : "krets") +
        "&id=" +
        site.clubID
    );
    setFolderTree(data.data);
    setShowSelect(true);
  };

  const execute = () => {
    setLoading(true);
    FetchWithAuth(
      "/api/clubadmin/content/import_articles?to=" +
        props.target +
        "&ezid=" +
        folderID
    ).then((result) => {
      setLoading(false);
      if (result.error === false) {
        setShowSelect(false);
        setFolderID(0);
        if (props.afterSuccess) {
          props.afterSuccess();
        }
      } else {
        window.alert(result.data);
      }
    });
  };

  const renderTreeItem = (parentID: number) => {
    const items = folderTree.list.filter(
      (item) => item.parent_node_id === parentID
    );
    return (
      <div>
        {items.map((item) => (
          <div>
            <div style={{ padding: "5px 0px" }}>
              <label style={{ color: item.article_count ? "black" : "gray" }}>
                <input
                  type="radio"
                  name="selected"
                  value={item.node_id}
                  disabled={item.article_count ? false : true}
                  onClick={() => {
                    setFolderID(item.node_id);
                  }}
                />{" "}
                {item.name}({item.article_count})
              </label>
            </div>
            <div style={{ marginLeft: 10 }}>{renderTreeItem(item.node_id)}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <span>
      <Button variant="link" size="sm" onClick={() => showSelecting()}>
        <i className="bi bi-upload"></i>&nbsp;Import fra gammel side
      </Button>
      {folderTree && (
        <Modal show={showSelect} centered>
          <ModalTitle style={{ padding: 10 }}>
            Velg import fra gammel {folderTree.root_name}
          </ModalTitle>
          <ModalBody>
            <div>{renderTreeItem(folderTree.root)}</div>
          </ModalBody>
          <ModalFooter>
            {loading && <span className="loading"></span>}
            <Button
              disabled={loading || !folderID}
              size="sm"
              onClick={() => {
                if (window.confirm("Er du sikker på å importere?")) {
                  execute();
                }
              }}
            >
              Import
            </Button>
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setShowSelect(false);
                setFolderID(0);
              }}
            >
              Lukke
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </span>
  );
};
