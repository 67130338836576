import {
  dmeConfig,
  initLanguage,
  registerDefaultWidgets,
  registerWidgetStyle,
  registerWidgetStyleOption,
  registerWidgetVariant,
  setDMEditorConfig,
} from "dmeditor";

import registerClubDropdown from "./widgets/club-dropdown";
import registerCarousel from "./widgets/carousel";
import registerTopNews from "./widgets/top-news";
import registerClubMembers from "./widgets/club-members-countdown";
import registerLinks from "./widgets/links";
import registerNewsList from "./widgets/news-list";
import registerResult from "./widgets/result";
import registerClubInfo from "./widgets/club-info";
import registerKretsInfo from "./widgets/krets-info";
import registerMemberList from "./widgets/memberlist";
import registerArticleDetail from "./widgets/article-detail";
import registerAnnualMeeting from "./widgets/annual-meeting";
import registerCalenderWidget from "./widgets/calendar-widget";
import { nanoid } from "nanoid";
import { imagePath } from "./widgets/util";
import { defaultStyles } from "./defaultStyle";

export const dmeditorInit = () => {
  initLanguage("nor-NO");
  registerDefaultWidgets();

  registerTopNews();
  registerCarousel();
  registerClubMembers();
  registerClubDropdown();
  registerLinks();
  registerNewsList();
  registerResult();
  registerClubInfo();
  registerKretsInfo();
  registerMemberList();
  registerArticleDetail();
  registerAnnualMeeting();
  registerCalenderWidget();

  registerWidgetStyleOption("text", [
    {
      name: "Article body",
      identifier: "article-body",
      cssStyle: `
        max-width: 960px;
        margin: auto;
    `,
    },
  ]);

  registerWidgetVariant({
    widget: "heading",
    identifier: "title",
    name: "Title",
    category: "basic",
    enabledSettings: [".value"],
    getDefaultData: () => {
      return {
        id: nanoid(),
        type: "heading:title",
        style: { type: "bridge-title" },
        data: { value: "Text", level: 2, settings: { level: 2 } },
      };
    },
  });

  const bridgeTitle = `
    h2{
      color: #000;
      background: transparent;
      font-size: 2.2rem;
      font-family: Cambria;
      font-weight: bold;
      padding: 50px 0 30px;
      margin: 0px;
      text-transform: uppercase;
    }    
  
    h2::after{
      content: ' ';
      display: block;
      width: 4.69rem;
      height: 5px;
      margin: 15px 0 0;
      background: var(--waring-color);
    }


    
  `;

  const bridgeHeadingStyle = {
    name: "Type",
    identifier: "type",
    options: [
      {
        name: "Bridge underline",
        identifier: "bridge-title",
        cssStyle: `
      ${bridgeTitle}
  `,
      },
      {
        name: "Bridge center",
        identifier: "bridge-title-center",
        cssStyle: `
      ${bridgeTitle}
      h2{
        text-align: center;
      }
  
      h2:after{
        margin-left:auto;
        margin-right: auto;
      }
  `,
      },
      {
        name: "Bridge (strip)",
        identifier: "bridge-title-strip",
        cssClasses: { h: "title-with-strip font-Cambria-blod" },
        cssStyle: `       
          
        `,
      },
    ],
  };

  registerWidgetStyle("heading:title", bridgeHeadingStyle);
  registerWidgetStyle("heading", bridgeHeadingStyle);

  ["heading:title", "heading", "bridge-top-news"].forEach((widget) => {
    registerWidgetStyle(widget, {
      name: "Background",
      identifier: "background",
      options: [
        {
          name: "gray",
          identifier: "gray",
          cssStyle: `
            & > div{
              background-color: var(--bg-color);
            }
          `,
        },
      ],
    });
  });

  [
    "heading:title",
    "heading",
    "bridge-top-news",
    "bridge-club-dropdown",
  ].forEach((widget) => {
    registerWidgetStyle(widget, {
      name: "Width",
      identifier: "width",
      options: [
        {
          name: "Full",
          identifier: "fullwidth",
          cssStyle: `
          & > *{
            width: var(--dme-container-width);
            margin-left: calc( ( var(--dme-main-width) - var(--dme-container-width) ) / 2 );
            padding-left:calc( ( var(--dme-container-width) - var(--dme-main-width) ) / 2 );
            padding-right:calc( ( var(--dme-container-width) - var(--dme-main-width) ) / 2 );                    
          }
          `,
        },
      ],
    });
  });

  registerWidgetStyle("list", {
    name: "Background",
    identifier: "background",
    options: [
      {
        name: "Gray",
        identifier: "gray",
        cssStyle: `
        background-color: var(--bg-color)
    `,
      },
      {
        name: "White",
        identifier: "white",
        cssStyle: `
        background-color: #ffffff
    `,
      },
    ],
  });

  registerWidgetStyle("list", {
    name: "Width",
    identifier: "width",
    options: [
      {
        name: "Full",
        identifier: "full",
        cssStyle: `
        margin-left: calc( ( 100% - var(--dme-container-width) ) /2 );
        margin-right: calc( ( 100% - var(--dme-container-width) ) /2 );
    `,
      },
      {
        name: "Background full",
        identifier: "background-full",
        cssStyle: `
        margin-left: calc( ( 100% - var(--dme-container-width) )/2 );
        margin-right: calc( ( 100% - var(--dme-container-width) )/2 );
        padding-left: calc( ( var(--dme-container-width) - 100% )/2 );
        padding-right: calc( ( var(--dme-container-width) - 100% )/2 );
        
    `,
      },
    ],
  });

  registerWidgetStyle("list", {
    name: "Padding up down",
    identifier: "padding-up-down",
    options: [
      {
        name: "Small",
        identifier: "small",
        cssStyle: `
        padding-top: 40px;
        padding-bottom: 40px;
    `,
      },
    ],
  });

  registerWidgetStyleOption("image", [
    {
      name: "Page title",
      identifier: "page-title",
      cssStyle: `
        text-align:center;
        margin-top: 20px;

        .dme-w-image img{
          max-width: 150px;
        }
      `,
    },
  ]);

  registerWidgetStyleOption("tabs", [
    {
      name: "Underline",
      identifier: "underline",
      cssStyle: `
        
        .dme-w-nav-item{
          border:none;
          padding: 5px 10px;
          background: none;     
          border-bottom: 3px solid rgba(0,0,0,0);     
          color:rgb(50, 111, 75);          
        }

        .dme-w-nav-item:hover{
          color: var(--tab-hover-color);
        }

        .dme-w-nav-item.dme-w-active{
          border-bottom-color: var(--waring-color);
          font-weight: bold;
        }

        div[role='tabpanel']{
          padding: 10px;
        }
      `,
    },
  ]);

  registerWidgetStyleOption("button", [
    {
      name: "Primary",
      identifier: "primary",
      cssClasses: { button: "btn btn-success" },
      cssStyle: `        

      `,
    },
    {
      name: "Secondary",
      identifier: "secondary",
      cssClasses: { button: "btn btn-warning" },
      cssStyle: `     
        background: var(--bs-btn-hover-bg)
        `,
    },
    {
      name: "Light",
      identifier: "light",
      cssClasses: { button: "btn btn-light" },
      cssStyle: "",
    },
    {
      name: "Link",
      identifier: "link",
      cssClasses: { button: "btn btn-link" },
      cssStyle: "",
    },
  ]);

  registerWidgetStyleOption("collapsable-text", [
    {
      name: "Bridge",
      identifier: "bridge",
      cssClasses: {
        "button-container": "text-center",
        button: "btn btn-primary",
      },
      cssStyle: "",
    },
  ]);

  ["hero-text", "bridge-club-members-countdown"].forEach((widget) =>
    registerWidgetStyleOption(widget, [
      {
        name: "Frontpage row space",
        identifier: "frontpage-row-space",
        cssStyle: `
          margin-top: 50px;
        `,
      },
    ])
  );

  for (const widget of Object.keys(defaultStyles)) {
    registerWidgetStyleOption(widget, [
      { identifier: "_default", name: "Default", ...defaultStyles[widget] },
    ]);
  }

  setDMEditorConfig({
    general: {
      imagePath: (path: string, size?: string) => {
        return imagePath(path, size === "thumbnail");
      },
      projectStyles: {
        default: `
        --project-main-color: blue;
        --project-main-bg-color: #e0e0ff;
      
        --dme-container-width: 100vw;
        --dme-main-width: 1200px;

        .preview-dmeditor.contenttype-site &{
          --dme-container-width: clamp(960px, calc(90vw * 0.75 - 10px),  90vw);
          --dme-main-width: calc( var(--dme-container-width) - 100px );
          width: var(--dme-main-width);
          margin: auto;

          .dme-blocktype-hero-text{
            --dme-container-width: clamp(960px, calc(90vw * 0.75 - 10px),  90vw);
          }

        }

        strong, b{
          font-weight: bold;
        }

        &.dme-viewmode-mobile, &.dme-viewmode-tablet{
            --dme-main-width: 100vw;   
        }

        .carousel-item{
          display:block;
        }

        // .dme-blocktype-hero-text{
        //   --dme-container-width: clamp( 100px, 100vw, 1600px );
        // }

        .dme-blocktype-hero-text > .dme-w-list{
          padding-right: 50px;
        }

        p{
          line-height: 1.5;
        }

        &.dme-viewmode-mobile
        {          
            .dme-w-hero + .dme-w-list p{
                padding: 0px 10px;
            }

            .dme-blocktype-hero-text > .dme-w-list{
              padding-right: 0px;
            }

            .dme-blockvariant-title h2{
                font-size: 1.5rem;
            }
                .dme-blockvariant-title h2:not(.title-with-strip){
                    font-size: 1.25rem;
                    padding: 10px 20px;
                    background: #F0F0F0;
                    text-align: left;
                    margin: 0;
                    width: 100%;

                    &::after{
                        content: "";
                        display: block;
                        width: 2px;
                        height: 1.25rem;
                        margin: -24px 0 0 -10px;
                        background: var(--waring-color);
                    }            
                }

          .w-container{
            width:100% !important;
            margin: auto;
          }
        }

        [contenteditable]:focus {
          outline: 0px solid transparent;
      }
      
        --waring-color:#FDA129;
        --tab-hover-color: var(--waring-color);
        --font-Cambria:Cambria;

        /* set below to global css */
        
        --bg-color:#F0F0F0;
        --bs-link-color: #326f4b !important;
        --bs-link-hover-color: #128843 !important;

        --bs-link-color-rgb: 50,111,75;
        --bs-link-hover-color-rgb: 18, 136, 67;

        --first-level-title-font-size:2.2rem;
        --secondary-title-font-size:1.8rem;
        --three-level-title-font-size:1.5rem; 
        font-size: 16px;
  
  
        .w-container{
        //   min-width: 960px;
        //   width:62.5vw;
        //   max-width: 1600px;
        //   margin: auto;
        }              

        .btn-success{
          --bs-btn-color: #fff;
          --bs-btn-bg: #198754;
          --bs-btn-border-color: #198754;
          --bs-btn-hover-color: #fff;
          --bs-btn-hover-bg: #157347;
          --bs-btn-hover-border-color: #146c43;
          --bs-btn-focus-shadow-rgb: 60,153,110;
          --bs-btn-active-color: #fff;
          --bs-btn-active-bg: #146c43;
          --bs-btn-active-border-color: #13653f;
          --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
          --bs-btn-disabled-color: #fff;
          --bs-btn-disabled-bg: #198754;
          --bs-btn-disabled-border-color: #198754;
        }

        .btn-warning{
          --bs-btn-color: #fff!important;
          --bs-btn-bg: var(--waring-color)!important;
          --bs-btn-border-color: var(--waring-color)!important;
          --bs-btn-hover-color: #fff!important;
          --bs-btn-hover-bg: #fdab3f!important;
          --bs-btn-hover-border-color: #fdab3f!important;
          --bs-btn-focus-shadow-rgb: 217,164,6!important;
          --bs-btn-active-color: #fff!important;
          --bs-btn-active-bg: #fdab3f!important;
          --bs-btn-active-border-color: #fdab3f!important;
          --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125)!important;
          --bs-btn-disabled-color: #fff!important;
          --bs-btn-disabled-bg: #fdab3f!important;
          --bs-btn-disabled-border-color: #fdab3f!important;
        }

        .bg-blue-500{
          background: #198754;
        }                 
        
        `,
      },
      deviceWidth: {
        mobile: 560,
        tablet: 960,
        pc: 8000,
      },
    },
    editor: {
      defaultTheme: "default",
      favouriteWidgets: [
        "text",
        "heading:title",
        "heading",
        "image",
        "table",
        "accordion",
        "tabs",
      ],
      zIndex: 500,
      colors: {
        text: [
          { color: "#000000" },
          { color: "#333333" },
          { color: "#cccccc" },
          { color: "#ffffff" },
        ],
        border: [
          { color: "#000000" },
          { color: "#333333" },
          { color: "#666666" },
          { color: "#999999" },
          { color: "#cccccc" },
          { color: "#ffffff" },
        ],
        background: [
          { color: "#ffffff", name: "White" },
          { color: "#cccccc", name: "Light white" },
          { color: "#dddddd" },
          { color: "#ffe6de" },
          { color: "#666666" },
          { color: "#034323" },
          { color: "#433803" },
          { color: "#430318" },
          { color: "#432603" },
        ],
      },
      enableEditControl: true,
      ui: {
        // "bg-editarea": "#646c71",
      },
      categories: [{ identifier: "bridge", name: "Bridge" }],
      defaultStyle: {
        tabs: { _: "_default" },
        table: { _: "_default" },
      },
    },
    widgets: {
      button: {
        defaultStyle: { _: "primary" },
      },
      "collapsable-text": {
        defaultStyle: { _: "bridge" },
      },
    },
  });
};
