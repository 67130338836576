import { MainSettings, ViewSettingsType } from "digimaker-ui";
import { Link } from "react-router-dom";
import {
  Delete,
  SetPriority,
  SetToTop,
  CopyFilepath,
} from "digimaker-ui/actions";
import util from "digimaker-ui/util";
import { Hide } from "./components/Hide";
import { ArticlePreview } from "./components/content/ArticlePreview";
import { nbfEnv, siteEnv } from "shared/src/widgets/siteEnv";
import { ImagePreview } from "./components/content/ImagePreview";

export const getMainConfig = (content: any) => {
  return {
    view: true,
    list: ["article"],
    new: ["article"],
    actions: [
      { link: "/fullcreate/{_location_id}/article", name: "Create article" },
    ],
  } as MainSettings;
};

let roles: Array<string> = [];
export const setRoles = (v: Array<string>) => {
  roles = v;
};

export const isBridgeAdmin = () => {
  return roles.includes("bridge-admin");
};

export const getListConfig = (parent: any, contenttype: string) => {
  switch (contenttype) {
    case "article":
      return {
        show_header: false,
        can_dd: true,
        show_table_header: true,
        pagination: 9,
        can_select: true,
        contenttype: "article",
        sort_default: [
          ["priority", "desc"],
          ["is_archived", "asc"],
          ["ref", "desc"],
          ["modified", "desc"],
        ],
        sort: {
          id: "desc",
          published: "desc",
          is_archived: "asc",
          modified: "desc",
        },
        columns: [
          { header: "ID", field: "id" },
          {
            header: "Tittel",
            render: (content: any) => <span>{content.title}</span>,
          },
          { header: "Hovedbilder", field: "coverimage" },
          {
            header: "Forfatter",
            render: (content: any) => (
              <span
                className="author-name"
                title={content.metadata.author_name}
              >
                {content.metadata.author_name}
              </span>
            ),
          },
          { header: "Opprettet", field: "published" },
          { header: "Arkivert", field: "is_archived" },
          { header: "Modifisert", field: "modified" },
        ],
        actions: [Delete],
        row_actions: [
          ArticlePreview,
          {
            link: "/fulledit/{_location_id}?from=/main/" + siteEnv.folderNews,
            title: "Redigere",
            icon: "fas fa-edit",
          },
          Hide,
          (props) => <Delete iconOnly={true} {...props} />,
          SetToTop,
        ],
        viewmode: "list",
      };
    case "template":
      return {
        show_header: false,
        show_table_header: true,
        pagination: 9,
        can_select: false,
        contenttype: "template",
        sort_default: [
          ["priority", "desc"],
          ["modified", "desc"],
        ],
        sort: { id: "desc", published: "desc", modified: "desc" },
        columns: [
          { header: "ID", field: "id" },
          {
            header: "Tittel",
            render: (content: any) => <span>{content.title}</span>,
          },
          {
            header: "Forfatter",
            render: (content: any) => (
              <span
                className="author-name"
                title={content.metadata.author_name}
              >
                {content.metadata.author_name}
              </span>
            ),
          },
          { header: "Opprettet", field: "published" },
          { header: "Modifisert", field: "modified" },
        ],
        row_actions: [
          {
            link:
              "/fulledit/{_location_id}?from=/main/" + nbfEnv.folderTemplate,
            title: "Redigere",
            icon: "fas fa-edit",
          },
          (props) => <Delete iconOnly={true} {...props} />,
        ],
        viewmode: "list",
      };
    case "annual_report":
      return {
        show_header: false,
        show_table_header: true,
        pagination: 10,
        can_select: false,
        contenttype: "annual_report",
        sort_default: [["published", "desc"]],
        sort: { published: "desc", modified: "desc" },
        columns: [
          { header: "ID", field: "id" },
          {
            header: "År",
            render: (content: any) => <span>{content.year.value}</span>,
          },
          { header: "Opprettet", field: "published" },
        ],
        row_actions: [
          {
            link:
              "/website/annual_report/edit/{_location_id}?from=/main/" +
              siteEnv.folderAnnualMeeting,
            title: "Redigere",
            icon: "fas fa-edit",
          },
          (props) => <Delete iconOnly={true} {...props} />,
        ],
        viewmode: "list",
      };
    case "event":
      return {
        show_header: false,
        show_table_header: true,
        pagination: 10,
        can_select: false,
        contenttype: "event",
        sort_default: [["date", "desc"]],
        sort: { date: "desc", published: "desc", modified: "desc" },
        columns: [
          { header: "ID", field: "id" },
          {
            header: "Tittel",
            render: (content: any) => (
              <span>
                <Link to={"/website/view/" + content.location.id}>
                  {content.title}
                </Link>
              </span>
            ),
          },
          { header: "Dato", field: "date" },
          {
            header: "Forfatter",
            render: (content: any) => (
              <span
                className="author-name"
                title={content.metadata.author_name}
              >
                {content.metadata.author_name}
              </span>
            ),
          },
          { header: "Opprettet", field: "published" },
        ],
        row_actions:
          isBridgeAdmin() || parent.location.id === siteEnv.folderEvents
            ? [
                {
                  link:
                    "/website/edit/{_location_id}?from=/main/" +
                    siteEnv.folderEvents +
                    "?tab={_from_id}",
                  title: "Redigere",
                  icon: "fas fa-edit",
                },
                (props) => <Delete iconOnly={true} {...props} />,
              ]
            : [],
        viewmode: "list",
      };
    case "block":
      return {
        show_header: true,
        sort_default: [
          ["priority", "desc"],
          ["id", "desc"],
        ],
        columns: [
          { header: "ID", field: "id" },
          { header: "Tittel", field: "title" },
          { header: "Type", field: "block_type" },
        ],
        row_actions: [
          {
            link:
              "/website/edit/{_location_id}?from=/main/" + siteEnv.folderBlock,
            title: "Redigere",
            icon: "fas fa-edit",
          },
          (props) => <Delete iconOnly={true} {...props} />,
        ],
      };
    case "link":
      return {
        show_header: true,
        show_table_header: false,
        sort_default: [
          ["priority", "desc"],
          ["id", "desc"],
        ],
        columns: [
          { header: "ID", field: "id" },
          { header: "Tittel", field: "title" },
          { field: "link" },
          { header: "Bilder", field: "image" },
        ],
        row_actions: [
          {
            link: "/website/edit/{_location_id}?from=/main/{_from_id}",
            title: "Redigere",
            icon: "fas fa-edit",
          },
          SetToTop,
          (props) => <Delete iconOnly={true} {...props} />,
        ],
      };
    case "image":
      return {
        can_select: true,
        row_actions:
          isBridgeAdmin() || parent.location.id === siteEnv.folderImage
            ? [
                {
                  link:
                    "/website/edit/image/{id}?from=/main/" +
                    siteEnv.folderImage +
                    "?tab={_from_id}",
                  title: "Redigere",
                  icon: "fas fa-edit",
                },
                (props) => <Delete iconOnly={true} {...props} />,
              ]
            : [],
        columns: [],
        actions: [Delete],
        blockFields: [
          "name",
          {
            render: (content) => (
              <div>
                <ImagePreview content={content} />
              </div>
            ),
          },
        ],
        viewmode: "block",
        pagination: 20,
      };
    case "file":
      return {
        can_dd: false,
        can_select: true,
        sort: {
          id: "desc",
          title: "asc",
          published: "desc",
          modified: "desc",
        },
        actions: [Delete],
        row_actions:
          isBridgeAdmin() || parent.location.id === siteEnv.folderFile
            ? [
                CopyFilepath,
                {
                  link:
                    "/website/edit/file/{id}?from=/main/" +
                    siteEnv.folderFile +
                    "?tab={_from_id}",
                  title: "Redigere",
                  icon: "fas fa-edit",
                },
                (props) => <Delete iconOnly={true} {...props} />,
              ]
            : [CopyFilepath],
        pagination: 20,
        columns: [
          { header: "ID", field: "id" },
          { header: "Navn", field: "title" },
          { header: "Opprettet", field: "published" },
          { header: "Oppdatert", field: "modified" },
        ],
      };
    default:
      return {};
  }
};

export const getViewSettings = (contentType: string) => {
  if (contentType == "folder" || contentType == "article") {
    return {
      inline_fields: ["title"],
      block_fields: ["title"],
      browselist: {
        viewmode: "list",
        columns: [
          { header: "ID", field: "id" },
          { header: "Title", field: "title" },
        ],
        sort_default: [["published", "desc"]],
      },
    } as ViewSettingsType;
  }

  if (contentType == "file") {
    return {
      inline_fields: ["title"],
      block_fields: [
        {
          render: (content: any) => (
            <span>
              <a
                href={util.washVariables(
                  process.env.REACT_APP_ASSET_URL as string,
                  { imagepath: content.path }
                )}
                target="_blank"
              >
                {content.title}
              </a>
            </span>
          ),
        },
        "published",
      ],
      browselist: {
        viewmode: "block",
        columns: [
          { header: "ID", field: "id" },
          { header: "Title", field: "title" },
        ],
        sort_default: [["published", "desc"]],
      },
    } as ViewSettingsType;
  }

  return {
    inline_fields: ["image"],
    block_fields: ["name", "image"],
    browselist: {
      viewmode: "block",
      columns: ["name"],
      sort_default: [["id", "desc"]],
      pagination: 25,
    },
  } as ViewSettingsType;
};

export const getEditField = (contenttype: string) => {
  let contentTypeField = {
    article: "fullbody",
    folder: "full_body",
    // "folder":'body'
  };
  return contentTypeField[contenttype];
};

export const getCreateInitData = () => {
  return [
    {
      id: "a1",
      type: "heading",
      data: "Ny artikkel",
      settings: {
        level: 2,
      },
      dm_field: "title",
    },
    {
      id: "a2",
      type: "image",
      data: {
        url: "http://adzt2clavc.dcp.dev.digimaker.no/var/images/thumbnail/images/z/zip/upload-918282482-carousel_image.jpg",
      },
      settings: {
        style: {
          textAlign: "center",
          width: "50%",
          margin: "auto",
        },
      },
      source: {
        sourceType: "input", //input/select
        sourceData: {},
      },
      dm_field: "coverimage",
    },
    {
      id: "a3",
      type: "text",
      data: [
        {
          type: "paragraph",
          children: [
            {
              text: "summary text",
            },
          ],
        },
      ],
      settings: {
        common: {},
      },
      dm_field: "summary",
    },
    {
      id: "a4",
      type: "text",
      data: [
        {
          type: "paragraph",
          children: [
            {
              text: "Article body",
            },
          ],
        },
      ],
      settings: {
        common: {},
      },
    },
  ];
};
